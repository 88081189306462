<template>
  <div>
    <v-card class="rounded-lg">
      <v-card-title class="grey--text text--darken-2">
        {{ title }}
        <v-spacer></v-spacer>

        <v-menu
          left
          bottom
          offset-y
          v-if="
            $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'
          "
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :disabled="loading">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="openDialogSearch">
              <v-list-item-avatar>
                <v-icon left>mdi-magnify</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Pencarian</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="(action, indexAction) in actions"
              :key="indexAction"
              :disabled="loading"
              @click="setEmit(action.name)"
            >
              <v-list-item-avatar>
                <v-icon v-if="action.icon" left>{{ action.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ action.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          text
          class="text-none grey--text text--darken-1"
          @click="openDialogSearch"
          v-else
          ><v-icon left>mdi-magnify</v-icon>Pencarian</v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          dense
          v-model="selected"
          :headers="headers"
          :items="data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          loading-text="Sedang memuat"
          no-data-text="Belum ada data"
          no-results-text="Data tidak ditemukan"
          sort-by-text="Urutkan berdasarkan"
          :items-per-page="15"
          class="transparent"
          height="calc(100vh - 325px)"
          fixed-header
          :footer-props="footerProps"
          :item-key="gridkey"
          :header-props="{ 'sort-icon': 'mdi-menu-up' }"
        >
          <template
            v-slot:top
            v-if="
              $vuetify.breakpoint.name != 'sm' &&
              $vuetify.breakpoint.name != 'xs'
            "
          >
            <div class="ma-2" v-if="actions && actions.length > 0">
              <v-btn
                v-for="(action, indexAction) in actions"
                :key="indexAction"
                small
                :color="action.color"
                class="ma-1 text-none"
                :disabled="loading"
                @click="setEmit(action.name)"
              >
                <v-icon v-if="action.icon" left>{{ action.icon }}</v-icon>
                {{ action.text }}
              </v-btn>
            </div>
            <v-divider></v-divider>
          </template>

          <template v-slot:body="{ items, headers, isMobile }">
            <tbody v-if="!isMobile && items.length > 0">
              <tr
                v-for="(item, indexItem) in items"
                :key="item[gridkey]"
                :class="`${
                  selected[0] && item == selected[0] ? 'row-selected' : ''
                }`"
                @click="doSelectRow(item)"
              >
                <td
                  :class="`text-${header.align} ${
                    header.divider ? 'v-data-table__divider' : ''
                  }`"
                  v-for="(header, indexHeader) in headers"
                  :key="indexHeader"
                >
                  <div
                    v-if="header.type == 'action' && header.options.length > 0"
                  >
                    <v-tooltip
                      bottom
                      v-for="(action, indexAction) in header.options"
                      :key="indexAction"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          :disabled="getDisabledActionButton(action, item)"
                          class="mr-2"
                          :color="action.color"
                          v-on="on"
                          @click="setEmit(action.name, item)"
                        >
                          <v-icon small>{{ action.icon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ action.text }}</span>
                    </v-tooltip>
                  </div>
                  <div v-else-if="header.type == 'image'">
                    <v-tooltip bottom v-if="item[header.value]">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          @click="openDialogDetailImage(item[header.value])"
                        >
                          <v-avatar size="36px" rounded>
                            <v-img
                              :src="`${item[header.value]}?w=36&h=36`"
                              class="grey lighten-2"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                        </v-btn>
                      </template>
                      <span>Detail Gambar</span>
                    </v-tooltip>

                    <v-avatar size="36px" rounded v-else color="accent">
                      <v-icon dark>mdi-image-off</v-icon>
                    </v-avatar>
                  </div>
                  <div v-else-if="!item[header.value]">-</div>
                  <div v-else-if="header.type == 'nominal'" class="text-right">
                    {{ $helpers.numberFormat(item[header.value], 0, ",", ".") }}
                  </div>
                  <div v-else-if="header.type == 'chip'">
                    <v-chip
                      small
                      :color="
                        header.options.color
                          ? header.options.color[item[header.value]]
                          : ''
                      "
                    >
                      {{
                        header.options.text
                          ? header.options.text[item[header.value]]
                          : item[header.value]
                      }}
                    </v-chip>
                  </div>
                  <div v-else-if="header.type == 'datetime'">
                    {{
                      $date(item[header.value]).format("DD MMM YYYY HH:mm:ss")
                    }}
                  </div>
                  <div v-else-if="header.type == 'date'">
                    {{ $date(item[header.value]).format("DD MMM YYYY") }}
                  </div>
                  <div v-else-if="header.type == 'text-html'">
                    {{ convertTextHtml(item[header.value]) }}
                    <v-btn
                      text
                      x-small
                      color="primary"
                      @click="openDialogDetailText(indexItem, header.value)"
                      v-if="convertTextHtml(item[header.value]).length >= 130"
                      >Selengkapnya</v-btn
                    >
                  </div>
                  <div v-else-if="header.type == 'button'">
                    <v-btn
                      text
                      small
                      color="primary"
                      @click="$emit(header.name, indexItem)"
                      >{{ header.text }}</v-btn
                    >
                  </div>
                  <div v-else>{{ item[header.value] }}</div>
                </td>
              </tr>
              <tr></tr>
            </tbody>
            <tbody v-else-if="isMobile && items.length > 0">
              <tr
                class="v-data-table__mobile-table-row"
                v-for="(item, indexItem) in items"
                :key="item[gridkey]"
              >
                <td
                  class="v-data-table__mobile-row"
                  v-for="(header, indexHeader) in headers"
                  :key="indexHeader"
                >
                  <div class="v-data-table__mobile-row__header">
                    {{ header.text }}
                  </div>
                  <div class="v-data-table__mobile-row__cell">
                    <div
                      v-if="
                        header.type == 'action' && header.options.length > 0
                      "
                    >
                      <v-tooltip
                        bottom
                        v-for="(action, indexAction) in header.options"
                        :key="indexAction"
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon
                            :disabled="getDisabledActionButton(action, item)"
                            class="mr-2"
                            :color="action.color"
                            v-on="on"
                            @click="setEmit(action.name, item)"
                            >{{ action.icon }}</v-icon
                          >
                        </template>
                        <span>{{ action.text }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="header.type == 'image'">
                      <v-tooltip bottom v-if="item[header.value]">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                            @click="openDialogDetailImage(item[header.value])"
                          >
                            <v-avatar size="36px" rounded>
                              <v-img
                                :src="`${item[header.value]}?w=36&h=36`"
                                class="grey lighten-2"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>
                          </v-btn>
                        </template>
                        <span>Detail Gambar</span>
                      </v-tooltip>

                      <v-avatar size="36px" rounded v-else color="indigo">
                        <v-icon dark>mdi-account-circle</v-icon>
                      </v-avatar>
                    </div>
                    <div v-else-if="!item[header.value]">-</div>
                    <div
                      v-else-if="header.type == 'nominal'"
                      class="text-right"
                    >
                      {{
                        $helpers.numberFormat(item[header.value], 0, ",", ".")
                      }}
                    </div>
                    <div v-else-if="header.type == 'chip'">
                      <v-chip
                        small
                        :color="
                          header.options.color
                            ? header.options.color[item[header.value]]
                            : ''
                        "
                      >
                        {{
                          header.options.text
                            ? header.options.text[item[header.value]]
                            : item[header.value]
                        }}
                      </v-chip>
                    </div>
                    <div v-else-if="header.type == 'datetime'">
                      {{
                        $date(item[header.value]).format("DD MMM YYYY HH:mm")
                      }}
                    </div>
                    <div v-else-if="header.type == 'date'">
                      {{ $date(item[header.value]).format("DD MMM YYYY") }}
                    </div>
                    <div v-else-if="header.type == 'text-html'">
                      {{ convertTextHtml(item[header.value]) }}
                      <v-btn
                        text
                        x-small
                        color="primary"
                        @click="openDialogDetailText(indexItem, header.value)"
                        v-if="convertTextHtml(item[header.value]).length >= 130"
                        >Selengkapnya</v-btn
                      >
                    </div>
                    <div v-else-if="header.type == 'button'">
                      <v-btn
                        text
                        small
                        color="primary"
                        @click="$emit(header.name, indexItem)"
                        >{{ header.text }}</v-btn
                      >
                    </div>
                    <div v-else>{{ item[header.value] }}</div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="v-data-table__empty-wrapper">
                <td :colspan="!isMobile ? headers.length : ''">
                  Belum ada data
                </td>
              </tr>
            </tbody>
          </template>

          <template v-slot:[`footer.page-text`]="props">
            {{ props.pageStart }}-{{ props.pageStop }} dari total
            {{ props.itemsLength }} data
            <v-btn
              text
              class="ml-4 text-none grey--text text--darken-1"
              small
              @click="fetch"
              ><v-icon left>mdi-sync</v-icon> Perbarui</v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="detailImage.dialog"
      v-if="detailImage.dialog"
      max-width="300"
    >
      <v-card>
        <v-card-text class="pa-0">
          <v-img
            :src="detailImage.imagePath"
            min-height="300"
            min-width="300"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="detailImage.dialog = false"
            >Tutup</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="search.dialog" scrollable persistent max-width="350">
      <v-card class="rounded-lg">
        <v-card-title class="grey--text text--darken-2"
          >Kolom Pencarian</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text style="max-height: 400px">
          <v-form @keyup.native.enter="doSearch">
            <div v-for="(search, index) in searchs" :key="index">
              <v-text-field
                :label="search.text"
                type="text"
                autocomplete="off"
                v-model="searchs[index].searchValue.value"
                filled
                dense
                clearable
                :autofocus="index == 0"
                v-if="search.type === 'string'"
              ></v-text-field>
              <v-dialog
                :ref="`dialog-date-${index}`"
                v-model="searchs[index].dialog"
                :return-value.sync="searchs[index].searchValue.value"
                persistent
                width="290px"
                v-if="search.type === 'date'"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    autocomplete="off"
                    v-model="searchs[index].searchValue.value"
                    :label="searchs[index].text"
                    readonly
                    filled
                    dense
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchs[index].searchValue.value"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs[`dialog-date-${index}`][0].save(
                        searchs[index].searchValue.value
                      )
                    "
                    >Pilih</v-btn
                  >
                  <v-btn
                    text
                    color="grey darken-1"
                    @click="searchs[index].dialog = false"
                    >Batal</v-btn
                  >
                </v-date-picker>
              </v-dialog>
              <v-select
                :items="searchs[index].option"
                :label="searchs[index].text"
                v-model="searchs[index].searchValue.value"
                filled
                dense
                clearable
                attach
                v-if="search.type === 'option'"
              ></v-select>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="doSearch">Cari</v-btn>
          <v-btn color="secondary" text @click="doSearchReset">Reset</v-btn>
          <v-btn color="grey darken-1" text @click="search.dialog = false"
            >Tutup</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="detailText.dialog" scrollable max-width="400">
      <v-card>
        <v-card-title>Detail Deskripsi</v-card-title>
        <v-card-text
          v-html="detailText.content"
          style="max-height: 400px"
        ></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="detailText.dialog = false"
            >Tutup</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "TheGrid",
  props: {
    title: {
      type: String,
      default: "",
    },
    endPoint: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    gridkey: {
      type: String,
      required: true,
    },
    filters: {
      type: Array,
      default: null,
    },
    actions: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    totalData: 0,
    selected: [],
    data: [],
    loading: true,
    options: {},
    footerProps: {
      "show-current-page": true,
      "show-first-last-page": true,
      "items-per-page-options": [15, 30, 50, 100],
      "items-per-page-text": "Data per halaman",
      "page-text": "{0} - {1} dari total {2}",
    },
    search: {
      dialog: false,
    },
    detailImage: {
      dialog: false,
      imagePath: "",
    },
    detailText: {
      dialog: false,
      content: "",
    },
    searchs: [],
  }),

  created() {
    let _self = this;
    this.headers.forEach(function (header, index) {
      if (header.searchType) {
        let arraySearch = {};
        if (header.searchType === "option") {
          arraySearch = {
            type: header.searchType,
            text: header.text,
            searchValue: {
              type: "string",
              field: header.value,
              comparison: "eq",
              value: null,
            },
            option: header.searchOptions,
          };
        } else if (header.searchType === "date") {
          arraySearch = {
            type: header.searchType,
            text: header.text,
            searchValue: {
              type: "date",
              field: header.value,
              comparison: "eq",
              value: null,
            },
            dialog: false,
          };
        } else {
          arraySearch = {
            type: header.searchType,
            text: header.text,
            searchValue: {
              type: "string",
              field: header.value,
              comparison: "any",
              value: null,
            },
          };
        }
        _self.searchs.push(arraySearch);
      }
    });
  },

  watch: {
    options: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },

  methods: {
    async fetch() {
      this.filterOn = false;
      this.loading = true;

      let sort = "";
      let dir = "";

      if (this.options.sortBy && this.options.sortBy.length > 0) {
        sort = this.options.sortBy[0];
      } else {
        sort = "";
      }

      if (this.options.sortDesc && this.options.sortDesc.length > 0) {
        if (this.options.sortDesc[0]) {
          dir = "DESC";
        } else {
          dir = "ASC";
        }
      } else {
        dir = "DESC";
      }

      let searchs = [];

      if (this.filters !== null) {
        searchs = [...this.filters];
      }

      this.searchs.forEach(function (search, index) {
        searchs.push(search.searchValue);
      });

      await this.axios
        .get(this.endPoint, {
          params: {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sort: sort,
            dir: dir,
            filter: searchs.filter(function (search, index) {
              return (
                search.value !== null && typeof search.value != "undefined"
              );
            }),
          },
        })
        .then((res) => {
          let data = res.data;
          switch (data.status) {
            case "success":
              this.data = data.result.data;
              this.totalData = data.result.pagination.totalData;
              break;
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      this.loading = false;
    },
    doSelectRow(data) {
      if (this.selected.length > 0) {
        if (this.selected[0] === data) {
          this.selected = [];
        } else {
          this.selected = [];
          this.selected.push(data);
        }
      } else {
        this.selected.push(data);
      }
    },
    doSearch() {
      this.search.dialog = false;
      this.fetch();
    },
    doSearchReset() {
      let _self = this;
      this.searchs.forEach(function (search, index) {
        _self.searchs[index].searchValue.value = null;
      });
      this.search.dialog = false;
      this.fetch();
    },
    openDialogDetailImage(imagePath) {
      this.detailImage.dialog = true;
      this.detailImage.imagePath = imagePath;
    },
    openDialogSearch() {
      this.search.dialog = true;
    },
    setEmit(name, value) {
      this.$emit(`action:${name}`, value);
    },
    getDisabledActionButton(action, item) {
      if (action.disabled) {
        if (action.disabled.value && Array.isArray(action.disabled.value)) {
          return action.disabled.value.includes(item[action.disabled.field]);
        } else {
          if (item[action.disabled.field] == action.disabled.value) {
            return true;
          }
        }
      }

      return false;
    },
    convertTextHtml(strHtml) {
      let str = this.$helpers.removeHtmlTag(strHtml);

      if (str.length > 130) {
        return str.substring(0, 130) + "...";
      }

      return str;
    },
    openDialogDetailText(index, field) {
      this.detailText.dialog = true;
      this.detailText.content = this.data[index][field];
    },
  },
};
</script>

<style>
.row-selected {
  background: #e1f5fe;
}
.theme--light.v-data-table tbody tr:not(:last-child) td:last-child,
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.theme--light.v-data-table .v-data-table__divider {
  border-right: thin solid rgba(0, 0, 0, 0.12) !important;
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 12%) !important;
}
.theme--light.v-data-table .v-data-table__divider {
    border-right: thin solid rgba(0,0,0,.12) !important;
}
/* .theme--dark.v-data-table tbody tr:not(:last-child) td:last-child,
.theme--dark.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row) {
  border-bottom: 1px solid rgba(255, 248, 248, 0.27) !important;
} */
</style>
