<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">

    <Snackbar ref="snackbar"/>

    <!-- DIALOG CREATE -->
    <v-dialog v-model="dialog.add_merchant" persistent width="550">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #F05326;"
        >
          <span class="white--text">
            Tambah Merchant
          </span>
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog()" color="white">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text>
          <ValidationObserver ref="observer">
            <ValidationProvider name="Nama Merchant" rules="required" v-slot="{ errors }">
              <v-text-field
                outlined
                dense
                flat
                v-model="form.name"
                label="Masukkan Nama Merchant"
                color="#F05326"
                :error-messages="errors">
              </v-text-field>
            </ValidationProvider>
          </ValidationObserver>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="#F05326"
            class="white--text text-capitalize"
            depressed
            @click="save()">
            {{ form.state === 'create' ? 'Tambah' : 'Simpan' }} Merchant
          </v-btn>
          <v-btn
            class="black--text text-capitalize"
            depressed
            @click="closeDialog()">
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-5">
      <div class="title black--text mb-2">List Merchant</div>
      <v-row justify="space-between" class="mt-2">
        <v-col cols="4">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Search Merchant . . ."
              autocomplete="off"
              v-model="searching"
              :readonly="process.run"
              clearable
              color="#F05326"
              @click:clear="searching = ''"
              v-on:keyup.enter="fetch()"
              prepend-inner-icon="mdi-magnify">
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <div class="d-flex float-right">
            <v-btn 
              depressed
              @click="openDialog('create', {})"
              color="#F05326" 
              class="text-capitalize ml-5 white--text">
              Add Merchant
              <!-- <v-icon right>mdi-filter-variant</v-icon> -->
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table-tbody">
          <v-data-table
            class="table-list"
            hide-default-footer
            fixed-header
            :loading="$store.state.process.run || process.run"
            :headers="headers"
            :items="merchantList.list"
            :items-per-page.sync="merchantList.limit"
            :page.sync="merchantList.page"
            :server-items-length="merchantList.total_data"
            item-key="id"
            :footer-props="{
              itemsPerPageText : 'Data per halaman',
              'items-per-page-options': [5,10, 20, 50, 100, 200, 350]
            }"
            >
            <template slot="footer" slot-scope="{ props }">
              <Pagination 
                @reload="fetch(page)"
                @changeLimit="limit = $event; fetch()" 
                @changePage="page = $event; fetch($event)" 
                :model="props"/>
            </template>

            <template slot="item" slot-scope="props">
              <tr class="mx-3 px-3 box-shadow">
                <td class="text-start white">
                  {{ props.item.name }}
                </td>
                <td class="text-start white">
                  {{ props.item.created_at | datetimeFull }}
                </td>
                <td class="text-right white">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="openDialog('update', props.item)"
                      >
                        <v-icon>mdi-pencil-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Merchant</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="warning"
                        v-bind="attrs"
                        v-on="on"
                        @click="details(props.item)">
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Detail Company</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import GridTable from '@/components/GridTable'
import Pagination from '@/components/Pagination.vue'
import Snackbar from '@/components/Snackbar.vue'

export default {
  components: { GridTable, Pagination, Snackbar },
  data() {
    return {
      limit: 10,
      page: 1,
      selected: {
        pagination: 0
      },
      headers: [
        { text: 'Merchant Name',align: 'start',value: 'name',sortable: false },
        { text: 'Date Created',align: 'start',value: 'created_at',sortable: false },
        { text: 'Action',align: 'end',value: 'action',sortable: false }
      ],
      merchantList: {},
      member: {},
      searching:"",
      form: {
        id: "",
        name: '',
        state: 'create'
      },
      dialog: {
        add_merchant: false
      },
      process: {
        run: false
      },
    }
  },
  watch:{
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    searching: _.debounce(function(newVal) {
      this.searching = newVal;
      this.fetch()
    }, 500),
  },
  created(){
    this.fetch()
  },
  mounted() {},
  methods:{
    changePage (event) {
      if (event === undefined) {
        this.selected.pagination = 0
      } 
    },
    closeDialog() {
      this.dialog.add_merchant = false;
      this.form.name = '';
      this.form.id = '';
      this.form.state = 'create'
      this.$refs.observer.reset()
    },
    openDialog(state, item) {
      this.dialog.add_merchant = true;
      this.form.state = state;
      if (state == 'update') {
        this.form.id = item.id;
        this.form.name = item.name;
      }else {
        this.$refs.observer.reset()
        this.form.id = '';
        this.form.name = '';
      }
    },
    filter(){
      this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
    },
    async fetch(paging){
      let _self = this;
      _self.process.run = true

      await get(`merchant/v1/superuser/list`,{
        params:{
          limit: this.limit,
          page: paging,
          sort: "created_at",
          dir: "asc",
          q: this.searching
        }
      }).then(response => {
        let res = response.data
        this.merchantList = res.data
        this.process.run = false
        this.$store.state.process.run = false
      })
    },
    async save() {
      this.process.run = true

      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        let url = ''
        if (this.form.state == 'create') {
          url = `merchant/v1/create`
        }else {
          url = `merchant/v1/update/${this.form.id}`
        }

        await post(url, this.form)
        .then(response => {
          let res = response.data
          this.process.run = false
          this.$store.state.process.run = false
          this.$refs.snackbar.open("#4CAF50", `${res.message}`);
          this.closeDialog()
        }).catch(error => {
          this.process.run = false
          this.$refs.snackbar.open("#e53935", `${error.response.data.message}`);
        })
      }else {
        this.process.run = false
      }
    }
  }
}
</script>

<style>

</style>